import styled from "styled-components";
import Btn from "../Button";

const ErrorBoundary = () => {
  return (
    <ErrorBoundaryWrapper className="flex flex-col gap-2 items-center justify-center">
      <h2>Something went wrong while loading this page.</h2>
      <Btn onClick={() => window.location.reload()}>Reload</Btn>
    </ErrorBoundaryWrapper>
  );
};

export default ErrorBoundary;

const ErrorBoundaryWrapper = styled.div`
  height: 100vh;
`;
