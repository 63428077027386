// ------------- import external dependencies ------------
import { StateCreator } from "zustand";

// ------------- import internal dependencies --------------
import { ToastTypes } from "./type";

const intialState: Omit<ToastTypes, "updateToast" | "reset"> = {
  toastOpen: false,
  status: "default",
  title: "",
  message: "",
};

const createToastSlice: StateCreator<ToastTypes> = (set) => ({
  ...intialState,
  updateToast(toast: Omit<ToastTypes, "updateToast" | "reset">) {
    return set(() => ({
      ...toast,
    }));
  },
  reset() {
    return set(() => ({
      ...intialState,
    }));
  },
});

export default createToastSlice;
