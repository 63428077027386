// ------------- import external dependencies ------------
import { useState, useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

// --------------- import internal dependencies --------------

import SkipToContent from "../../shared/Ally";
import SideBar from "./components/SideBar";
// import ContentHeader from "./components/ContentHeader";
// import { adminLayoutChildren } from "../../routes/routes";

function AdminLayout() {
  // ----- state component managers -----
  const [minimize, setMinimize] = useState(false);
  // ------ intialize custom hooks ------
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const isLoggedIn = localStorage.getItem("isLoggedIn");

    if (!isLoggedIn) {
      console.log("Ran till this point");

      navigate("/signin");
      return;
    }

    if (pathname === "/admin") {
      console.log("Ran till this point 2");
      navigate("/admin/dashboard");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <SkipToContent content="Skip to Navigation" section="main">
        <main>
          <SideBar minimize={minimize} setMinimize={setMinimize} />
          <MainContent className={minimize ? "ml-[90px]" : ""}>
            <Outlet />
          </MainContent>
        </main>
      </SkipToContent>
    </>
  );
}

export default AdminLayout;

const MainContent = styled.div`
  margin-left: 280px;
  padding: 1rem;
`;
