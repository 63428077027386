// ------------- import external dependencies ------------
import { StateCreator } from "zustand";

// ----------- import internal dependencies -----------
import { LoginTypes } from "./type";

const intialState = {
  email: "",
  password: "",
};

/**
 * Handle user login slice
 * @param set
 * @returns
 */
const createUserLoginSlice: StateCreator<LoginTypes> = (set) => ({
  ...intialState,
  updateLoginDetails(data: { email: string; password: string }) {
    return set(() => {
      const { email, password } = data;
      return {
        email,
        password,
      };
    });
  },
});

export default createUserLoginSlice;
