// ------------ import external dependencies --------------
import { create } from "zustand";
import { persist } from "zustand/middleware";
import { mountStoreDevtool } from "simple-zustand-devtools";

// ------------ import internal dependencies ------------
import { ThemeTypes, ToastTypes, UserTypes, RefreshType, LoginTypes } from "./type";
import createThemeSlice from "./themeSlice";
import createToastSlice from "./toastSlice";
import createUserSlice from "./userSlice";
import createRefreshSlice from "./refreshSlice";
import createUserLoginSlice from "./loginSlice";

/**
 * Global store level
 */
export const useStoreWrapper = create<ThemeTypes & ToastTypes & UserTypes & RefreshType & LoginTypes>()(
  persist(
    (...a) => ({
      ...createThemeSlice(...a),
      ...createToastSlice(...a),
      ...createUserSlice(...a),
      ...createRefreshSlice(...a),
      ...createUserLoginSlice(...a),
    }),
    {
      name: "cardtonic-admin",
    }
  )
);

if (process.env.NODE_ENV === "development") {
  mountStoreDevtool("Store", useStoreWrapper);
}
