// ------------- import external dependencies ------------
import { StateCreator } from "zustand";

// ------------- import internal dependencies ------------
import { UserTypes } from "./type";

const initialState: Omit<UserTypes, "updateUser"> = {
  user: {},
};

const createUserSlice: StateCreator<UserTypes> = (set) => ({
  ...initialState,
  updateUser(user: any) {
    return set(() => ({
      user,
    }));
  },
});

export default createUserSlice;
