/* eslint-disable */
// ------------- import external dependencies -------------
import axios, { type AxiosInstance } from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";

// ----------- import internal dependencies ------------
import { refreshToken } from "./auth";

const url =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_CARDTONIC_STAGING_URL
    : process.env.REACT_APP_CARDTONIC_LIVE_URL;

export const instance: AxiosInstance = axios.create({
  baseURL: url,
  withCredentials: true,
  // timeout: 3000,
});

/**
 * axios instance response interceptor
 * @param instance
 */
const createAxiosResponseInterceptor = (instance: AxiosInstance) => {
  instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      return Promise.reject(error);
    }
  );
};

const refetchEndpoint = async (failedRequest: any) => {
  const res = await refreshToken();

  if (res !== null) {
    Promise.resolve();
  } else {
    localStorage.removeItem("isLoggedIn");
    window.location.replace("/signin");
    return Promise.reject();
  }
};

createAxiosResponseInterceptor(instance);
createAuthRefreshInterceptor(instance, refetchEndpoint, {
  pauseInstanceWhileRefreshing: false,
});
