// ------------ import external dependencies -----------
import styled from "styled-components";

export const AuthWrapper = styled.main`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: hidden;
  position: relative;
`;

export const LogoWrapper = styled.div`
  height: 70px;
  width: 70px;
  // background: var(--background-1);
  position: absolute;
  bottom: 50px;
  right: 50px;
  border-radius: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > img[data-theme="dark"] {
    height: 40px;
  }
`;
