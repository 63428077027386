// ------------- import external dependencies ------------
import { StateCreator } from "zustand";

// ----------- import internal dependencies -------------
import { RefreshType } from "./type";

const intialState: Omit<RefreshType, "updateRefresh" | "resetRefresh" | "updatePendingKeys" | "updateOtherKeys"> = {
  refereshing: false,
  pendingQueryKeys: [],
  otherKeys: [],
};

const createRefreshSlice: StateCreator<RefreshType> = (set) => ({
  ...intialState,
  updateRefresh(status: boolean) {
    return set((state) => ({
      refereshing: status,
    }));
  },
  updatePendingKeys(query: string[]) {
    return set((state) => ({
      pendingQueryKeys: [...state.pendingQueryKeys, query],
    }));
  },
  updateOtherKeys(query: string[]) {
    return set((state) => ({
      otherKeys: [...state.otherKeys, query],
    }));
  },
  resetRefresh() {
    return set((state) => ({
      ...state,
      ...intialState,
    }));
  },
});

export default createRefreshSlice;
