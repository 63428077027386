// --------------- import external dependencies ------------
import { Suspense } from "react";
import { RouterProvider } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

// -------------- import internal dependencies  -------------
import { useStoreWrapper } from "./store";
import { authRoutes } from "./routes/routes";
import Toast from "./components/Toast";
import LayoutLoader from "./components/Loaders/LayoutLoader";
// ------ import styles --------
import "./css/main.css";

export let pendingQueries: any = [];

/**
 * Configure react-query query client
 */
export const client = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failure, error: any) => {
        if (failure > 0) {
          return false;
        }
        return true;
      },
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  // ------ intialize custom hooks -------
  const open = useStoreWrapper((state) => state.toastOpen);
  const message = useStoreWrapper((state) => state.message);

  return (
    <>
      <QueryClientProvider client={client}>
        <Suspense fallback={<LayoutLoader />}>
          <RouterProvider router={authRoutes} />
          {open && message ? <Toast /> : null}
        </Suspense>
      </QueryClientProvider>
    </>
  );
}

export default App;
