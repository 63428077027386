// ------------- import external dependencies ------------
import { StateCreator } from "zustand";

// ----------- import internal dependencies -----------
import { ThemeTypes } from "./type";

// ------ get user default system theme -------
let currTheme = window.localStorage.getItem("theme");
const defaultDark = window.matchMedia("(prefers-color-scheme: dark)").matches;

if (!currTheme) {
  currTheme = defaultDark ? "dark" : "light";
}

const intialState = {
  theme: currTheme,
};

const createThemeSlice: StateCreator<ThemeTypes> = (set) => ({
  ...intialState,
  updateTheme(theme: string) {
    return set(() => {
      return {
        theme: theme,
      };
    });
  },
});

export default createThemeSlice;
