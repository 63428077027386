// ------------ import external dependencies ----------
import { useMutation } from "@tanstack/react-query";

// ----------- import internal dependencies -----------
import { sendVerification, suspendUser, freezeUser, deleteAdminAccount, restoreAccount, logOut } from "../api/auth";

function useAuth() {
  // ----- resend user verification ------
  const resendVerification = useMutation((req: any) => sendVerification(req));

  // ----- suspend user ------
  const updateUserSuspend = useMutation((req: any) => suspendUser(req));

  // ----- freeze user ------
  const updateUserFreeze = useMutation((req: any) => freezeUser(req));

  // ----- delete user ------
  const deleteUser = useMutation((req: any) => deleteAdminAccount(req));

  // ------ restore user ------
  const restoreUser = useMutation((req: any) => restoreAccount(req));

  const logOutUser = useMutation(() => logOut());

  return {
    resendVerification,
    updateUserSuspend,
    updateUserFreeze,
    deleteUser,
    restoreUser,
    logOutUser,
  };
}

export default useAuth;
