// ------------ import external dependencies -------------
import { useEffect } from "react";
import styled from "styled-components";
import { RiErrorWarningLine, RiMessage3Line, RiCheckLine } from "react-icons/ri";

// ------------ import internal dependencies ------------
import { useStoreWrapper } from "../../store";

function Toast() {
  // ------- Intialize custom component -------
  const open = useStoreWrapper((state) => state.toastOpen);
  const title = useStoreWrapper((state) => state.title);
  const message = useStoreWrapper((state) => state.message);
  const status = useStoreWrapper((state) => state.status);
  const reset = useStoreWrapper((state) => state.reset);

  // -------- status config icons --------
  const statusConfig = {
    error: {
      icon: <RiErrorWarningLine />,
      class: "bg-accent-4",
    },
    default: {
      icon: <RiMessage3Line />,
      class: "bg-accent-2",
    },
    success: {
      icon: <RiCheckLine />,
      class: "bg-accent-2",
    },
  };

  useEffect(() => {
    let timer: any;

    // ------ reset the toast ------
    if (open) {
      timer = setTimeout(() => {
        reset();
      }, 3000);
    }

    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ToastWrapper>
      <div className="toast">
        <div>
          <div className={`icon-indicator ${statusConfig[status].class}`}>{statusConfig[status].icon}</div>
        </div>
        <div className="text-clip overflow-clip whitespace-nowrap">
          <div className="font-bold">{title}</div>
          <div className="message">{message}</div>
        </div>
      </div>
    </ToastWrapper>
  );
}

export default Toast;

// ------ component styles -------
const ToastWrapper = styled.div`
  width: 100%;
  // display: flex;
  justify-content: center;
  position: fixed;
  top: 50px;
  z-index: 99999;

  .toast {
    max-width: 450px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    min-height: 90px;
    box-shadow: 0px 0px 20px rgba(145, 145, 145, 0.3);
    border-radius: 120px;
    background: var(--background);
    display: flex;
    align-items: center;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    column-gap: 20px;

    & .icon-indicator {
      height: 50px;
      width: 50px;
      border-radius: 50px;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        font-size: 28px;
        color: var(--neutralWhite);
      }
    }
  }

  .message {
    display: block;
    text-wrap: wrap;
  }
`;
