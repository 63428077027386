// ------------ import external dependencies -------------
import { useEffect } from "react";
import { useNavigate, useLocation, Outlet } from "react-router-dom";

// ----------- import internal dependencies ------------
import SkipToContent from "../../shared/Ally";
// ------ import assets -------
import useAuth from "../../hooks/useAuth";
import { AuthWrapper, LogoWrapper } from "./style";
import { useStoreWrapper } from "../../store";
import { ReactComponent as LogoLight } from "../../assets/img/logo-light.svg";
import { ReactComponent as LogoDark } from "../../assets/img/logo-dark.svg";

interface Props {
  page?: string;
  children?: React.ReactNode;
}

function AuthLayout({ page, children }: Props) {
  // ------- intialize custom hooks --------
  const theme = useStoreWrapper((state) => state.theme);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { logOutUser } = useAuth();

  const { mutateAsync: logOut } = logOutUser;

  // ------- fetch the login status from localstorage -------
  const isLoggedIn = localStorage.getItem("isLoggedIn");

  const signOut = async () => {
    try {
      await logOut();
    } catch (error) {}
  };

  useEffect(() => {
    console.log(pathname);

    if (pathname === "/getlost") {
      signOut();
      localStorage.clear();
      navigate("/signin", { replace: true });
      return;
    }

    if (isLoggedIn === "true") {
      navigate("/admin/dashboard", { replace: true });
    } else {
      navigate("/signin", { replace: true });
    }
  }, []);

  return (
    <>
      <SkipToContent section="main" content="Skip to Content">
        <AuthWrapper>
          <Outlet />
          <LogoWrapper>
            {theme === "light" ? <LogoLight data-theme={theme} /> : <LogoDark data-theme={theme} />}
          </LogoWrapper>
        </AuthWrapper>
      </SkipToContent>
    </>
  );
}

export default AuthLayout;
