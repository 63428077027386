// ------------ import external dependencies -----------
import Skeleton from "react-loading-skeleton";
import styled from "styled-components";
import Lottie from "react-lottie";
import * as loader from "../../assets/lottie/loading.json";

function LayoutLoader() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loader,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <LoaderWrapper>
      <Lottie options={defaultOptions} width={600} />
      {/* <aside>
        <Skeleton height="100vh" />
      </aside>

      <ContentLoader>
        <Skeleton count={7} className="block mt-1" inline={false} containerClassName="flex-1" height={100} />
      </ContentLoader> */}
    </LoaderWrapper>
  );
}

export default LayoutLoader;

// ----- component styles ------
const LoaderWrapper = styled.div`
  gap: 40px;
  background: var(--background);
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  // & > aside {
  //   width: 280px;
  //   height: 100vh;
  //   position: fixed;
  // }
`;

const ContentLoader = styled.div`
  margin-left: 280px;
  padding-left: 4rem;
  padding-right: 4rem;
`;
