// -------------- import external dependencies ------------
import React from "react";
// ------------ import internal dependencies -------------
import { SkipToContentStyle } from "./style";

interface Props {
  section: string;
  content: string;
  children: React.ReactNode;
}

const SkipToContent = ({ section, content, children }: Props) => {
  return (
    <>
      <SkipToContentStyle className="skip text-white" href={`#${section}`} aria-label={content}>
        {content}
      </SkipToContentStyle>
      {children}
    </>
  );
};

export default SkipToContent;
