/* eslint-disable */
// ----------- import internal dependencies -------------
import { instance } from "./instance";
// import { useStoreWrapper } from "../store";

// const updateRefresh = useStoreWrapper().updateRefresh;

export interface loginObj {
  email: string;
  password: string;
  twoFACode?: string;
}

/**
 * Handle user login service
 * @param obj
 * @returns
 */
export const loginUser = async (obj: loginObj) => {
  return instance({
    url: "/auth/admin/login",
    method: "POST",
    data: { ...obj },
  });
};

/**
 * Handle token refresh for expired access token
 */
export const refreshToken = async () => {
  try {
    // @ts-ignore
    const res = await instance("/auth/refresh", { skipAuthRefresh: true });

    return res;
  } catch (error: any) {
    return null;
  }
};

/**
 * Create new Sub admin account
 * @param data
 * @returns
 */
export const signUpSubAdmin = async (data: any) => {
  return instance({
    url: "/auth/signup-sub-admin",
    method: "POST",
    data,
  });
};

/**
 * Update Admin Privileges
 * @param data
 * @returns
 */
export const updatePrivileges = async (data: any) => {
  const { id, privileges } = data;
  return instance({
    url: `/auth/privileges/${id}`,
    method: "PATCH",
    data: {
      privileges: {
        ...privileges,
      },
    },
  });
};

/**
 * Delete user admin account
 * @param id
 * @returns
 */
export const deleteAdminAccount = (id: string) => {
  return instance({
    url: `/auth/delete-user/${id}`,
    method: "DELETE",
  });
};

/**
 * Restore deleted account
 * @param id
 * @returns
 */
export const restoreAccount = (id: string) => {
  return instance({
    url: `/auth/delete-user/${id}`,
    method: "PUT",
  });
};

/**
 * Resend user verifications
 * @param req
 * @returns
 */
export const sendVerification = (req: any) => {
  const { id, data } = req;
  return instance({
    url: `/auth/verify-user/${id}`,
    method: "POST",
    data,
  });
};

/**
 * Suspend user
 * @param req
 * @returns
 */
export const suspendUser = async (req: any) => {
  return instance({
    url: `/auth/suspend-user/${req.userId}?suspended=${req.suspend}`,
    method: "PATCH",
  });
};

/**
 * freeze user
 * @param req
 * @returns
 */
export const freezeUser = async (req: any) => {
  return instance({
    url: `/auth/freeze-user/${req.userId}?freeze=${req.freeze}`,
    method: "PATCH",
  });
};

/**
 * Logout user nad end their session
 * @returns
 */
export const logOut = () =>
  instance({
    url: `/auth/logout`,
    method: "DELETE",
  });
