// ---------- import internal dependencies ------------
import { useStoreWrapper } from "../../store";

interface PropType {
  permission?: string | { [k: string]: { [J: string]: string } };
  children?: React.ReactNode;
  userRole?: string[];
}

function PermissionChecker({ permission, children, userRole }: PropType) {
  // ------ initialize custom hooks -------
  const user: any = useStoreWrapper((store) => store.user);
  const { privileges, role } = user;

  let value: any;

  if (!userRole) {
    return <>{children}</>;
  }

  if (userRole?.includes(role) && role === "superAdmin") {
    value = <>{children}</>;
    return value;
  }

  if (userRole?.includes(role)) {
    if (permission === "global") {
      value = <>{children}</>;
      return value;
    }

    let permissionKeys = Object.keys(permission as {});

    if (typeof permission === "object") {
      for (let ind = 0; ind < permissionKeys.length; ind++) {
        const permKey = permissionKeys[ind];
        const accessKey = permission[permKey].access;

        if (privileges[permKey] && privileges[permKey][accessKey]) {
          value = <>{children}</>;
          break;
        } else {
          value = null;
        }
      }
    }

    return value;
  }

  return null;
}

export default PermissionChecker;
